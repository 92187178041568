/**
 * Application theme
 *
 * Override and extend smooth-ui theme values
 * https://github.com/smooth-code/smooth-ui/blob/master/packages/shared/core/theme.js
 * https://smooth-ui.smooth-code.com/
 */

import { transparentize } from 'polished';
import { thd, theme } from '@smooth-ui/core-sc';
import settingsApp from 'theme/settingsApp';

/*
If overwriting the default Smooth-UI theme properties, and you're planning on
re-using them throughout the default export below, make sure to define them in
the outer scope, otherwise components attempting to use the value with throw errors
*/
const primary = '#034EA2';
const secondary = '#000';
const grey = '#E6E6E6';
const bluegrey = '#034EA2';
const green = '#034EA2';
const text = '#FFF';

export default {
  // Mandatory
  ...theme,
  settingsApp, // Bring in the merged app settings

  // Resets
  borderRadius: 0,
  error: thd('danger', '#dc3545'),
  gridMaxWidths: { sm: '100%', md: '100%', lg: '100%', xl: '1200px' },
  primary: primary,
  secondary: secondary,
  text: text,
  grey: grey,
  bluegrey: bluegrey,
  green: green,

  // Layout
  borderColor: thd('gray300', '#ddd'),
  containerWidth: '1200px',

  // Colours
  defaultFontColor: theme.gray800,
  // pointsValueColor: '#f90',

  // Input + Buttons
  inputBtnMinWidth: '200px',
  customInputHintColor: theme.gray600,
  customInputHintFontSize: '.875em',
  customInlineInputInvalidColor: theme.red,
  customCheckboxDisabledBackground: '#ced4da',

  // Header + Menu
  customHeaderBackground: primary,
  customHeaderColor: '#f8fbfe',
  customMenuBackground: primary,
  customMenuColor: '#fff',
  customMenuHighlight: primary,
  customMenuHighlightColor: '#000',
  customMenuFocus: transparentize(0.75, '#f4bd19'),
  customSubMenuBackground: '#151d29',
  customMobileMenuOverlayBackground: 'rgba(0, 0, 0, 0.5)',

  // Loading animations
  loadingBarColor: primary || '#f4bd19',
  loadingSpinnerPrimary: '#555',
  loadingSpinnerSecondary: '#eee',

  // Welcome component
  welcomeBackground: grey,
  welcomeColor: '#000',
  welcomeHighlight: '#000',

  // Breadcrumbs
  breadcrumbsBackground: '#000',
  breadcrumbsColor: '#fff',
  breadcrumbsContainerWidth: thd('containerWidth', '100%'),
  breadcrumbsLinkColor: '#fff',
  breadcrumbsLinkHoverColor: '#fff',
  breadcrumbsCurrentLinkColor: '#fff',
  breadcrumbsFontSize: '0.85rem',

  // Cart
  // miniCartBackground: '#fff',
  cartBadgeBackground: '#000',
  cartBadgeColor: '#fff',
  cartPointsBalanceBackground: primary,
  cartRemainingNegativeColor: thd('danger', '#dc3545'),

  // Rewards
  rewardCategoriesListContainerWidth: thd('containerWidth', '100%'),
  rewardCategoriesFilterContainerWidth: thd('containerWidth', '100%'),
  rewardListContainerWidth: thd('containerWidth', '100%'),
  rewardDetailContainerWidth: thd('containerWidth', '100%'),
  rewardDescription1ContainerWidth: thd('containerWidth', '100%'),
  rewardDescription2ContainerWidth: thd('containerWidth', '100%'),

  // Rewards - Quickview
  // quickviewBackground: '#333',
  // quickviewColor: '#fff',

  // Rewards - Wishlist
  //miniWishlistBackground: '#000',
  wishlistBadgeBackground: '#000',
  wishlistBadgeColor: '#fff',
  wishlistContainerWidth: '500px',
  wishlistIconActive: primary,
  wishlistIconInactive: '#fff',

  // Rewards - Highlight (featured/related rewards)
  rewardsHighlightBackground: '#fff',
  rewardsHighlightContainerWidth: thd('containerWidth', '100%'),

  // Statement
  statementHighlightColor: primary,
  statementStatus: {
    achieved: '#034EA2',
    onTrack: '#034EA2',
    close: '#ea7c22',
    behind: '#dc402a',
    inProgress: '#ea7c22',
    timeElapsed: '#C00D0D',
  },
  statementDoughnutBorder: '#A9A9A9',
  statementDoughnutRemainingTrack: '#A9A9A9',

  // Tooltips
  tooltipBackground: '#fff',
  tooltipBorderColor: '#fff',
  tooltipColor: thd('defaultFontColor', theme.gray800),

  // Pagination
  pagerActiveBackground: 'rgba(0,0,0,0.125)',
  pagerActiveColor: '#fff',
  // pagerColor: thd('gray800', 'inherit'),
  pagerBackgroundHover: 'rgba(0,0,0,0.125)',
};
