import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { css, up, styled, thd } from '@smooth-ui/core-sc';
import { Link } from 'react-router-dom';

import { withSettings } from 'containers/WebApp/SettingsContext';
import Logo from './images/Logo-Footer@2x.png';

const FooterWrapper = styled.footer`
  background-color: ${thd('primary', 'primary')};
  border-top: 8px solid #fff;
  text-transform: uppercase;
  color: #fff;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0.3rem 0 0.2rem 0;
  ${up(
    'md',
    css`
      flex-direction: row;
      justify-content: space-between;
    `
  )};
  div {
    font-size: 13px;
    padding: 5px 0;
    text-align: center;
    ${up(
      'md',
      css`
        padding-left: 1rem;
        text-align: left;
      `
    )};
  }
  ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${up(
      'md',
      css`
        flex-direction: row;
        padding-top: 1rem;
      `
    )};
  }
  li {
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    ${up(
      'md',
      css`
        padding: inherit;
        text-align: left;
        &:nth-last-child(1) {
          border-right: none;
        }
      `
    )};
    a {
      color: #fff;
      font-weight: normal;
      text-decoration: none;
      padding: 0.1rem 1rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Footer = ({ siteName }) => (
  <FooterWrapper>
    <FooterContainer>
      <div>
        <ul>
          <li>
            <Link to="/terms">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/faqs">FAQs</Link>
          </li>
          <li>
            <Link to="/sitemap">Sitemap</Link>
          </li>
        </ul>
        <div>© EPSON PREMIUM PARTNER REWARDS {new Date().getFullYear()}</div>
      </div>
      <p className="text--center">
        <img src={Logo} height="112" alt="Epson Premium Partner Rewards" />
      </p>
    </FooterContainer>
  </FooterWrapper>
);

Footer.propTypes = {
  siteName: PropTypes.string,
};

Footer.defaultProps = {
  siteName: 'Lori Program Default',
};

export default withSettings(Footer);
